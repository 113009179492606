import { executeRestAPICall } from "../services/generic.service";
import { CourseFeedResponse, CustomerDetails } from "./courses.types";


const ASSIGNED_COURSES_API = `${process.env.REACT_APP_LTI_BACKEND_API}/lti/courses-assigned-to-customer/feed`;
const CUSTOMER_DETAILS = `${process.env.REACT_APP_LTI_BACKEND_API}/lti/customer`;
const getCoursesAssignedToCustomerInContext = async (searchQuery?: string, skip=0, take=20) => {
  return await executeRestAPICall({
    method: "GET",
    url: `${ASSIGNED_COURSES_API}?q=${searchQuery ?? ""}&skip=${skip}&take=${take}`,
  }) as CourseFeedResponse;
};
const getCustomerDetails = async()=> {
  return await executeRestAPICall({
    method: "GET",
    url: `${CUSTOMER_DETAILS}`,
  }) as CustomerDetails;
}
export {getCoursesAssignedToCustomerInContext,getCustomerDetails};
