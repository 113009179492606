import { FC, useEffect, useRef } from "react";
import { Course } from "../../courses/courses.types";
import { isExamUnlockedOrCourseCompleted } from "../../utils/course.utils";
import { CourseProgressState } from "../course-progress.enum";
import { ChildCourseItem } from "./ChildCourseItem";
import { ChildCourseWithProgress } from "./types";

export type ChildCourseListProps = {
  items: ChildCourseWithProgress[];
  onItemSelect: (course: Course | "exam") => void;
  selected?: Course | "exam";
  parentState?: CourseProgressState;
};

export const ChildCourseList: FC<ChildCourseListProps> = (props) => {
  const { items, selected, onItemSelect, parentState } = props;
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (selected && ref.current) {
      let container = ref.current.parentElement;
      while (container && getComputedStyle(container).overflow !== "auto") {
        container = container.parentElement;
      }
      if (!container) return;
      const isInView =
        ref.current.offsetTop >= container.scrollTop &&
        ref.current.offsetTop < container.scrollTop + container.clientHeight;
      if (isInView) return;
      container.scrollTop = ref.current.offsetTop - container.offsetTop;
    }
  }, [selected]);

  return (
    <ol>
      {items.map((item, idx) => (
        <li
          key={item.child.course.id}
          ref={item.child.course === selected ? ref : null}
        >
          <ChildCourseItem
            item={item.child.course}
            isLocked={item.isLocked}
            state={item.progress?.state}
            isSelected={selected === item.child.course}
            onItemSelect={() => onItemSelect(item.child.course)}
            index={idx}
            count={items.length + 1}
          />
        </li>
      ))}
      <li className="w-full">
        <ChildCourseItem
          item="exam"
          isLocked={!isExamUnlockedOrCourseCompleted(parentState)}
          state={parentState}
          isSelected={selected === "exam"}
          onItemSelect={() => onItemSelect("exam")}
          index={items.length}
          count={items.length + 1}
        />
      </li>
    </ol>
  );
};
