/* eslint-disable jest/require-hook */
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import enNs1 from "./locales/locale-en.json";
import deNs1 from "./locales/locale-de.json";


export const defaultNS = "ns1";

i18next.use(initReactI18next).init({
  debug: true,
  fallbackLng: "de",
  defaultNS,
  resources: {
    en: {
      ns1: enNs1,
    },
    de: {
      ns1: deNs1,
    },
  },
});

export default i18next;
