import { Document, Page, Text, View, PDFViewer, Image, PDFDownloadLink, Font} from "@react-pdf/renderer";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Certificate } from "../@types/certificate.types";
import { Button } from "../components/buttons/Button";

import styles from "./certificate-viewer.styles";
import "../i18n";

export type CertificateProps = {
    firstName: string
    lastName: string
    orgName: string
    courseTitle: string
    completionDate: Date
    creditedHours?: number
}

export type CertificateViewerProps = {
  certificate: Certificate
  goBack: () => void
}


const getFilename = (certificate: CertificateProps) => {
  const username = (`${certificate.firstName?.[0] ?? ""}${certificate.lastName}`).toLowerCase();
  const month = certificate.completionDate.getMonth() + 1;
  const title = certificate.courseTitle.replace(/[^a-z0-9äöüß]+/ig, "-").toLowerCase();
  const dateStr = `${certificate.completionDate.getFullYear()}-${month < 10 ? "0" : ""}${month}`;
  return `${title}-${dateStr}-${username.toLowerCase()}.pdf`;
};

export const CertificateDocument: FC<CertificateProps> = (props) => {
  const {firstName, lastName, orgName, courseTitle, completionDate, creditedHours} = props;
  const {t} = useTranslation();

  useEffect(() => {
    // Disable hyphenation
    Font.registerHyphenationCallback((word) => [word]);
  }, []);

  return (<Document style={styles.document} title={getFilename(props)} author="Relias Learning Gmbh">
    <Page size="A4" style={styles.page}>
      <View style={styles.background}>
        <Image src={creditedHours ? "/certificate-rbp-bg.jpg" : "/certificate-bg.jpg"} style={styles.bgImage} />
      </View>
      <View style={styles.main}>
        <Text style={styles.certificateBelongsTo}>{t("certificates.thisCertificateAttestsThat")}</Text>
        <Text style={styles.learner}>{firstName} {lastName}</Text>
        <Text style={styles.orgName}>({orgName})</Text>
        <Text style={styles.forCourse}>{t("certificates.theCourse")}</Text>
        <Text style={styles.courseTitle}>{courseTitle}</Text>
        <Text style={styles.hasSuccessfullyCompleted}>{t("certificates.hasSuccessfullyCompleted")}</Text>
        <Text style={styles.completedOn}>{t("certificates.completedOn")}: {completionDate.toLocaleString("de-DE", {dateStyle: "medium", timeStyle: "short"})}</Text>
        { creditedHours && <Text>{t("certificates.creditedHours")}: {creditedHours}</Text>}
      </View>
    </Page>
  </Document>);
};

export const CertificateViewer: FC<CertificateViewerProps> = ({certificate, goBack}) => {
  const { t } = useTranslation();
  const props = {
    firstName: certificate.learner.learnerFirstName,
    lastName: certificate.learner.learnerLastName,
    orgName: certificate.learner.customers.name,
    courseTitle: certificate.courses.title,
    completionDate: new Date(certificate.completedOn),
    creditedHours: certificate.courses.rbp ?? undefined,
  };

  return (
    <div className="relative" style={{height: "100%", width: "100%"}}>
      <div className="absolute top-8 left-4 right-4
        flex justify-center content-stretch gap-4 opacity-60 hover:opacity-100">
        <Button
          className="my-0 mt-0 mb-0 border border-white"
          label={`❮ ${t("common.goBack")}`}
          action={goBack}
        />
        <PDFDownloadLink
          document={<CertificateDocument {...props} />}
          fileName={getFilename(props)}>
          <button
            className="cursor-pointer flex flex-row gap-2 text-sm
            font-semibold text-white justify-center content-center bg-gray-700 rounded-md
            px-4 py-2 hover:bg-gray-800 border border-white">
            <img src="/download.svg" alt="" className="w-4 h-4 my-0.5"/>
            <span>{t`certificates.download`}</span>
          </button>
        </PDFDownloadLink>
      </div>
      <PDFViewer style={{height: "100%", width: "100%"}} showToolbar={false}>
        <CertificateDocument {...props} />
      </PDFViewer>
    </div>
  );
};

