import { useParams } from "react-router-dom";
import { submitDeeplinkRequest } from "./deep-link.service";
import { DeeplinkResponse } from "./deep-link.types";
import { useState } from "react";
import { useCourseFeed } from "../courses/useCourseFeed";
import { useCourseSelection } from "./hooks/useCourseSelection";
import { SelectionPanel } from "./selection-panel.component";
import { CourseSearch } from "../courses/course-search";
import { useTranslation } from "react-i18next";
import { useErrorRedirect } from "../app/hooks/useErrorRedirect";
import { GENERIC_ERROR_MESSAGE } from "../@types/errors.types";

type Params = {
  ltik: string;
}

export const submitResponseToLMS = (response: DeeplinkResponse) => {
  // Note to self: This hack circumvents CORS issues.
  const form = document.getElementById("deepLinkForm") as HTMLFormElement;
  form.action = response.lmsEndpoint;
  form.method = "POST";
  const childNode = document.querySelector("input[name=JWT]") ?? document.createElement("input");
  childNode.setAttribute("type", "hidden");
  childNode.setAttribute("name", "JWT");
  childNode.setAttribute("value", response.deepLinkingMessage);
  form.appendChild(childNode);
  form.submit();
};

export const DeepLink: React.FC = () => {
  const { t } = useTranslation();
  const { ltik } = useParams<Params>();
  const { selection, selectionSet, select, deselect } = useCourseSelection();
  const [deeplinkingMessageResponse, setServerResponse] = useState<DeeplinkResponse>({
    deepLinkingMessage: "",
    lmsEndpoint: ""
  });

  const {
    courseFeed,
    setSearchQuery,
    isLoading,
    hasError,
    feedTriggerRef,
  } = useCourseFeed<HTMLDivElement>(30);
  useErrorRedirect(hasError, GENERIC_ERROR_MESSAGE);
  localStorage.setItem("lti_app_token", ltik as string);

  const submit = async () => {
    if (!selection.length) return;
    try {
      if (!selection.every((course) => course.courseName?.length)) {
        throw new Error("We don't have all course names, this should not happen.");
      }
      const response = await submitDeeplinkRequest(selection);
      setServerResponse(response);
      submitResponseToLMS(response);
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <main className="m-8 flex flex-col gap-4">
      {!!selection.length &&
        <SelectionPanel
          selection={selection}
          deselect={deselect}
          onSubmit={submit}
        />
      }
      <div className="flex flex-row gap-4 items-center mb-2">
        <img src={`${process.env.PUBLIC_URL}/relias-color.svg`} alt="Relias" className="h-6" />
        <CourseSearch onSearch={setSearchQuery} />
      </div>
      {isLoading ? <p>{t`common.loading`}</p>
        : courseFeed.length === 0 ? <p>{t`courses.bodyNoCoursesFound`}</p>
        : <>
          <ul className="flex flex-col gap-3">
            {courseFeed.map((item, index) => (
              <li key={index} className="flex flex-row gap-2 items-baseline">
                <input
                  type="checkbox"
                  id={`${item.course.id}`}
                  onChange={(e) => {
                    e.target.checked
                      ? select(item.course.id, item.course.title)
                      : deselect(item.course.id);
                  }}
                  checked={selectionSet.has(item.course.id)}
                />
                <label htmlFor={`${item.course.id}`}>
                  {item.course.title}
                </label>
              </li>
            ))}
          </ul>
          <div className="h-10" ref={feedTriggerRef} />
        </>
      }
      <form
        action={deeplinkingMessageResponse.lmsEndpoint}
        id="deepLinkForm"
        method="POST"
        hidden
      />
    </main>
  );
}
