import { FC, ReactElement, createContext, isValidElement, useEffect, useState } from "react";
import { TabPanelProps } from "./TabPanel";
import { TabList } from "./TabList";

export type TabIdString = string;
export const TabSetContext = createContext<TabIdString | undefined>(undefined);


export type TabSetProps = {
  children: ReactElement<TabPanelProps>[];
  onTabChange?: (tabId: TabIdString) => void;
  initialTab?: TabIdString;
};

const TabSet: FC<TabSetProps> = (props) => {
  const { children, onTabChange, initialTab } = props;
  const [activeTab, setActiveTab] = useState<TabIdString | undefined>(initialTab);

  const onTabClick = (tabId: TabIdString) => {
    if (activeTab === tabId) return;
    setActiveTab(tabId);
    onTabChange?.(tabId);
  };

  useEffect(() => {
    if (initialTab) return;
    const firstTab = children.find((child) => isValidElement(child))?.props.id;
    setActiveTab(firstTab);
  }, [children]);

  return (
    <TabSetContext.Provider value={activeTab}>
      <TabList activeTab={activeTab} setActiveTab={onTabClick}>
        {children}
      </TabList>
      {children}
    </TabSetContext.Provider>
  );
};

export default TabSet;
