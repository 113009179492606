import { Course } from "../../courses/courses.types";
import { isExamUnlockedOrCourseCompleted } from "../../utils/course.utils";
import { CourseProgressState } from "../course-progress.enum";
import { CourseProgress } from "../course-progress.types";
import { ChildCourseWithProgress } from "./types";

export const getNextChild = (
  children: ChildCourseWithProgress[],
  selected: Course | "exam" | undefined,
  parentProgress: CourseProgress | undefined,
) => {
  const nextIndex = children.findIndex((c) => c.child.course === selected) + 1;
  if (0 <= nextIndex && nextIndex < children.length) {
    const next = children[nextIndex];
    if (!next.isLocked) return children[nextIndex].child.course;
  } else if (
    nextIndex === children.length &&
    isExamUnlockedOrCourseCompleted(parentProgress?.state)
  ) {
    return "exam";
  } else {
    return undefined;
  }
};

export const orderChildrenWithProgress = (
  course: Course,
  childProgresses: Record<number, CourseProgress | null> | undefined,
): ChildCourseWithProgress[] => {
  const orderedWithProgress = course.children
    .sort((a, b) => a.order - b.order)
    .map((child) => ({
      child,
      isLocked: false,
      progress: childProgresses?.[child.course.id] ?? undefined,
    }));
  const firstLockedIndex = orderedWithProgress.findIndex(
    (_, index) =>
      index > 0 &&
      ![
        CourseProgressState.FINISHEDWATCHING,
        CourseProgressState.COMPLETED,
      ].includes(
        orderedWithProgress[index - 1].progress?.state ??
          CourseProgressState.NOTSTARTED,
      ),
  );
  if (firstLockedIndex > 0) {
    orderedWithProgress.forEach(
      (child, index) => (child.isLocked = index >= firstLockedIndex),
    );
  }
  return orderedWithProgress;
};
