import { PropsWithChildren, FC } from "react";
import { TabSetContext } from "./TabSet";

export type TabPanelProps = PropsWithChildren<{
  id: string;
  label: string;
}>;

const TabPanel: FC<TabPanelProps> = (props) => {
  const { id, children } = props;
  return (
    <TabSetContext.Consumer>
      {(activeTab) => {
        return (
          <div role="tabpanel" hidden={activeTab !== id}>
            {children}
          </div>
        );
      }}
    </TabSetContext.Consumer>
  );
};

export default TabPanel;
