import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../app/hooks/useWindowSize";
import { classNames } from "../../utils/classnames";

export type DownloadProgressModalProps = {
  progress: number;
  total: number;
  onCancel: () => void;
  onDownload: () => void;
  error?: string;
};

export type ProgressButtonProps = {
  progress: number;
  total: number;
  onClick: () => void;
};

const getOptimalColCount = (total: number, recursing = false): number => {
  const maxCols = total > 80 ? 16 : 10;
  if (total > 30) {
    return 10;
  }
  if (total <= maxCols) {
    return total;
  }
  for (let i = maxCols; i > 3; i--) {
    if (total % i === 0) {
      return i;
    }
  }
  return recursing ? 5 : getOptimalColCount(
    total % 2 === 0 ? total + 2 : total + 1, true);
};


const DownloadButton: FC<ProgressButtonProps> = ({ progress, total, onClick }) => {
  const { t } = useTranslation();
  const done = progress === total;
  const progressPercent = Math.floor(progress / total * 100);
  return <button
    className="relative w-full text-center rounded-md shadow-sm text-sm font-semibold bg-gray-200 gap-2 overflow-clip"
    onClick={onClick}
    disabled={!done}
  >
    <div
      style={{ right: `${100 - progressPercent}%` }}
      className="-z-1 absolute left-0 top-0 bg-gray-700 h-full rounded-r-lg"
    />
    <div className={classNames(
      "px-3 py-1.5 z-10 relative",
      done ? "text-white font-semibold" : "invert mix-blend-difference",
    )}>
      {progress === total ? t`common.download` : `${progressPercent}%`}
    </div>
  </button>;
};

export const DownloadProgressModal: FC<DownloadProgressModalProps> = (props) => {
  const { error, progress, total, onCancel, onDownload } = props;
  const { t } = useTranslation();
  const optimalColCount = getOptimalColCount(total);
  const [width] = useWindowSize();

  const message: string = error ? t`certificates.downloadError`
    : progress === total ? t`certificates.bulkDownloadReadyMessage`
      : t`certificates.bulkDownloadMessage`;

  return <div className="fixed inset-0 bg-black bg-opacity-50 z-[10_000] flex items-center justify-center mt-[80px]">
    <div className="relative">
      <div className={classNames(
        width < 720
          ? "min-w-[minmax(400px,90vw)] max-w-[90vw]"
          : "w-[600px]",
        "bg-white rounded-lg p-8 relative overflow-clip"
      )}>
        <div className="flex flex-col gap-8 items-center text-center text-balance">
          <p className={classNames(
            error && "text-red-600 font-semibold",
            "min-h-14 flex items-center justify-center",
          )}>{message}</p>
          {total < 200 && width >= 720 && <>
            <hr className="w-full border-b border-gray-200 -my-4" />
            <ul
              className="grid gap-4 w-fit"
              style={{
                gridTemplateColumns: `repeat(${optimalColCount}, 1fr)`
              }}
            >
              {Array(total).fill(0).map((_, index) => {
                const isDone = index < progress;
                const isCurrent = index === progress;
                return <li key={index} className={classNames(
                  "flex items-center justify-center transition-opacity duration-150",
                  isCurrent && "animate-pulse duration-75",
                  isDone ? "opacity-100" : "opacity-10"
                )}>
                  <img src={`${process.env.PUBLIC_URL}/certificate.svg`} alt="" />
                </li>;
              })}
            </ul>
          </>}
          <DownloadButton progress={progress} total={total} onClick={onDownload} />
        </div>
      </div>
      <button onClick={onCancel} className="group p-3 bg-gray-200 absolute top-0 right-0 rounded-bl-lg" >
        <img
          className="group-hover:scale-125 transition-all duration-150"
          src={`${process.env.PUBLIC_URL}/cross.svg`}
          alt={t`common.cancel`}
          title={t`common.cancel`}
        />
      </button>
    </div>
  </div >;
};
