import { executeRestAPICall } from "../services/generic.service";
import { DeeplinkResponse, DeeplinkedCourse } from "./deep-link.types";


const DEEPLINK_API = `${process.env.REACT_APP_LTI_BACKEND_API}/lti/deeplink`;
const submitDeeplinkRequest = async (courses:DeeplinkedCourse[]) => {
  return await executeRestAPICall({
    method: "POST",
    url: `${DEEPLINK_API}`,
    data: {
       courses
    }
  }) as DeeplinkResponse;
};


//TODO: need to use existing hook useCourseFeed and get courses 
// and show them in checkboxes and then send the selected course-ids to BE for deeplinking ..use the above function for that
export {submitDeeplinkRequest};