import { useQuery } from "@tanstack/react-query";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useErrorRedirect } from "../../app/hooks/useErrorRedirect";
import { Course } from "../../courses/courses.types";
import { isExamUnlockedOrCourseCompleted } from "../../utils/course.utils";
import { CourseProgressState } from "../course-progress.enum";
import { getMultipleCourseProgresses } from "../course-progress.service";
import { CourseDetails, CourseDetailsProps } from "../CourseDetails";
import { ChildCourseList } from "./ChildCourseList";
import { ExamItem } from "./ExamItem";
import { getNextChild, orderChildrenWithProgress } from "./util";

export type MultiCourseDetailsProps = Omit<
  CourseDetailsProps,
  "onNextCourse"
> & {
  onTakeExam: () => void;
};

export const MultiCourseDetails: FC<MultiCourseDetailsProps> = (props) => {
  const { course, courseProgress: parentProgress, onTakeExam } = props;
  const [selected, setSelected] = useState<Course | "exam">();
  const [hasUserSelectedItem, setHasUserSelectedItem] = useState(false);

  const { data: childProgresses, error: childProgError } = useQuery({
    queryKey: ["childProgresses"],
    queryFn: () =>
      getMultipleCourseProgresses(course.children.map((c) => c.course.id)),
    select: (data) => (data ? Object.fromEntries(data) : {}),
  });

  useErrorRedirect(childProgError);

  const children = useMemo(
    () => orderChildrenWithProgress(course, childProgresses) ?? [],
    [course, childProgresses],
  );

  useEffect(() => {
    if (hasUserSelectedItem) return;
    const examUnlocked = isExamUnlockedOrCourseCompleted(parentProgress?.state);
    if (examUnlocked) {
      setSelected("exam");
      return;
    }
    const lastUnlocked = [...children].reverse().find((c) => !c.isLocked);
    if (lastUnlocked) setSelected(lastUnlocked.child.course);
  }, [children, selected, hasUserSelectedItem, parentProgress]);

  const nextChild = useMemo(
    () => getNextChild(children, selected, parentProgress),
    [children, selected, parentProgress],
  );

  const selectNextChild = useCallback(() => {
    if (!nextChild) return;
    setSelected(nextChild);
    setHasUserSelectedItem(true);
  }, [nextChild]);

  return (
    <section className="flex flex-col md:flex-row">
      <div
        className="
      flex flex-col max-h-60 md:w-[50vw] lg:w-[480px]
      overflow-y-auto md:max-h-none
      border-b-gray-200 border-b md:border-b-0"
      >
        <div className="bg-[#ece9df] shadow-sm md:rounded-l-xl">
          <ChildCourseList
            items={children}
            onItemSelect={(item) => {
              setSelected(item);
              setHasUserSelectedItem(true);
            }}
            selected={selected}
            parentState={parentProgress?.state}
          />
        </div>
      </div>
      {selected === undefined ? (
        <></>
      ) : selected === "exam" ? (
        <ExamItem
          onTakeExam={onTakeExam}
          isExamFinished={
            parentProgress?.state === CourseProgressState.COMPLETED
          }
        />
      ) : (
        <div className="flex flex-col bg-white shadow-sm p-8 md:container gap-4 flex-grow">
          <h2 className="font-bold">{selected.title}</h2>
          <CourseDetails
            isChildCourse
            course={selected}
            onCourseLaunched={() => setHasUserSelectedItem(true)}
            onNextCourse={selectNextChild}
            courseProgress={childProgresses?.[selected.id] ?? undefined}
            nextCourseLabel={
              nextChild === "exam"
                ? "courseDetail.goToExam"
                : "courseDetail.nextCourse"
            }
          />
        </div>
      )}
    </section>
  );
};
