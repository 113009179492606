import { AxiosRequestConfig } from "./types";
import axios from "axios";
const executeRestAPICall = (options:AxiosRequestConfig) => {
  const onSuccess = (response:any) => {
    return response.data;
  };
  const onError = (error:any) => {
    return Promise.reject(error.response || error.message);
  };
  return axios({...options,
    timeout: 5000,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("lti_app_token")}`
    } }).then(onSuccess).catch(onError);
};

export {executeRestAPICall};
