import { FC, useMemo, useState } from "react";
import { getMyCertificates } from "./certificate.service";
import { PageLayout } from "../components/Pages/PageLayout";
import { PageTitle } from "../components/Pages/PageTitle";
import groupBy from "lodash/groupBy";
import { Loader } from "../loaders/loader.component";
import TabSet from "../components/TabSet/TabSet";
import TabPanel from "../components/TabSet/TabPanel";
import { Certificate } from "../@types/certificate.types";
import { useTranslation } from "react-i18next";
import { useErrorRedirect } from "../app/hooks/useErrorRedirect";
import { Button } from "../components";
import { DownloadProgressModal } from "./components/DownloadProgressModal";
import { useBulkDownload } from "./hooks/useBulkDownload";
import { getAbbreviatedUsername } from "./hooks/useCertificatePdf";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

const groupCertificatesByYearAndCourse = (certificates: Certificate[]) => {
  const groupedByYear = groupBy(certificates, (cert) => new Date(cert.completedOn).getFullYear());
  const sortByYearDesc = (a: { year: string }, b: { year: string }) => Number(b.year) - Number(a.year);
  return (Object.entries(groupedByYear)
    .map(([year, certificates]) => ({
      year,
      certificates: certificates.sort((a, b) =>
        a.courses.title.localeCompare(b.courses.title)
        || a.completedOn.localeCompare(b.completedOn)),
    }))
    .sort(sortByYearDesc)
  );
};

export type DownloadAllButtonProps = {
  onClick: () => void;
}

const DownloadAllButton: FC<DownloadAllButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();
  return <Button
    className="flex flex-row items-center justify-center w-fit"
    action={onClick}
  >
    <img
      src={`${process.env.PUBLIC_URL}/download.svg`} alt=""
      className="h-4"
    />
    {t`certificates.downloadAll`}
  </Button>;
};

const CertificateBlip: FC<{ certificate: Certificate }> = ({ certificate }) => {
  const completionDateFormatted = new Date(certificate.completedOn).toLocaleDateString("de-DE",
    { year: "numeric", month: "long", day: "numeric" });
  const completionTimeFormatted = new Date(certificate.completedOn).toLocaleTimeString("de-DE", {
    hour: "2-digit", minute: "2-digit"
  });
  const title = certificate.courses.title.replace(/ - /g, " – ");
  return <a
    href={`/certificate?certificate=${certificate.id}`}
    className="
  cursor-pointer w-full rounded-lg shadow-lg overflow-hidden bg-white
  text-left p-4 flex flex-col justify-around gap-4 hover:-translate-y-0.5 hover:shadow-xl">
    <p className="text-balance break-words line-clamp-3 font-semibold leading-snug">{title}</p>
    <div className="flex flex-row gap-2 items-center">
      <img src={`${process.env.PUBLIC_URL}/certificate.svg`} alt="" />
      <p>{completionDateFormatted}</p>
      <p className="text-gray-400">{completionTimeFormatted}</p>
    </div>
  </a>;
};

export const CertificatesPage: FC = () => {
  const { t } = useTranslation();
  const { data: certificates, isLoading, error } = useQuery({
    queryFn: getMyCertificates,
    queryKey: ["certificates"],
  });
  const groupedCertificates = useMemo(() => groupCertificatesByYearAndCourse(certificates ?? []), [certificates]);
  useErrorRedirect(error);
  const navigate = useNavigate();

  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const {
    initiate,
    progress,
    total,
    error: bulkDownloadError,
    cancel,
    downloadZip,
  } = useBulkDownload();

  const prepareBulkDownload = (certificates: Certificate[], year: string | number) => {
    const filename = `${t`certificates.title`}-${year}-${getAbbreviatedUsername(certificates[0])}`;
    initiate(certificates, filename);
    setShowDownloadModal(true);
  };

  const handleDownload = () => {
    downloadZip();
    setShowDownloadModal(false);
  };

  const cancelBulkDownload = () => {
    setShowDownloadModal(false);
    cancel();
  };

  const content =
    isLoading ? <Loader />
      : groupedCertificates.length === 0 ? <p>{t`certificates.noCertificatesReceivedYet`}</p>
        : <>
          <TabSet>
            {groupedCertificates.map(({ year, certificates }) =>
              <TabPanel
                id={year}
                key={year}
                label={year}
              >
                <section className="flex flex-col gap-8">
                  <DownloadAllButton onClick={() => prepareBulkDownload(certificates, year)} />
                  <div className="grid grid-cols-[repeat(auto-fill,280px)] gap-3">
                    {certificates.map((cert) =>
                      <CertificateBlip
                        key={`${year}-${cert.id}`}
                        certificate={cert}
                      />)}
                  </div>
                </section>
              </TabPanel>
            )}
          </TabSet>
          {showDownloadModal && <DownloadProgressModal
            error={bulkDownloadError}
            progress={progress}
            total={total}
            onCancel={cancelBulkDownload}
            onDownload={handleDownload}
          />}
        </>;

  return (
    <PageLayout>
      <Button
        className="flex-grow-0 w-fit"
        label={`❮ ${t("common.goBack")}`}
        action={() => navigate(-1)}
      />
      <PageTitle title={t`certificates.title`} />
      {content}
    </PageLayout>
  );
};
