import { useTranslation } from "react-i18next";
import { classNames } from "./utils/classnames";

const Header = () => {
  return <>
    <header
      className={classNames(
        "fixed top-0 left-0 right-0 z-50",
        "h-[80px] py-5 bg-gray-700 text-white text-center flex flex-row items-center gap-4 px-8"
      )}
    >
      <img src="/relias.svg" alt="Relias" className="max-h-10" />
      <p className="font-light text-slate-400 md:mr-8">LTI 1.3</p>
    </header>
    <div className="min-h-[80px]">&nbsp;</div> {/* account for fixed header */}
  </>;
};

export { Header };
