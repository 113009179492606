import { FunctionComponent } from "react";

export type RadialProgressProps = {
  progress: number,
  size?: number,
  strokeWidth?: number
  strokeColor?: string
  label?: JSX.Element
}
type SvgCircleProps = {size: number, strokeWidth: number};
type RadialProgressSvgProps = RadialProgressProps & SvgCircleProps;

const getDimensions = ({size, strokeWidth}: SvgCircleProps) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const c = `${size/2}px`;
  return {
    circumference,
    circleAttribs: { cx: c, cy: c, r: `${radius}px` }
  };
};

const Label: FunctionComponent<{progress: number}> = ({progress}) =>
  <div className="w-full absolute z-10 top-1/2 -translate-y-1/2">
    <div className="text-center font-semibold">{progress}%</div>
  </div>;

/**
 * Return a svg with two circles on top of each other where the upper circle's arc visualises the progress.
 */
const RadialProgressSvg: FunctionComponent<RadialProgressSvgProps> = ({size, strokeWidth, strokeColor, progress}) => {
  const {circleAttribs, circumference} = getDimensions({size, strokeWidth});
  const track = <circle {...circleAttribs}
    className={`stroke-gray-200 fill-transparent`} style={{strokeWidth: `${strokeWidth}px`}}
  />;
  const progressIndicator = <circle {...circleAttribs}
    className={`fill-transparent stroke-[10px]`}
    style={{
      strokeDashoffset: circumference - progress * circumference,
      strokeDasharray: `${circumference} ${circumference}`,
      strokeLinecap: "round",
      stroke: strokeColor ?? "black",
      strokeWidth: `${strokeWidth}px`
    }}
  />;
  return (
    <svg aria-hidden viewBox={`0 0 ${size} ${size}`} className="mx-auto">
      <g className="-rotate-90 origin-center">
        {track}
        {progressIndicator}
      </g>
    </svg>
  );
};


export const RadialProgressBar: FunctionComponent<RadialProgressProps> = (props) => {
  const progress = Math.max(0, Math.min(props.progress, 100));
  const size = props.size ?? 100;
  const strokeWidth = props.strokeWidth ?? size / 10;
  return <div>
    <div className="relative">
      <RadialProgressSvg
        progress={progress/100}
        size={size}
        strokeWidth={strokeWidth}
        strokeColor={props.strokeColor} />
      { props.label ?
        <div className="w-full absolute z-10 top-1/2 -translate-y-1/2">
          {props.label}
        </div>
        : <Label progress={progress} />
      }
    </div>
  </div>;
};

