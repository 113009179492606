import { FC, useEffect, useState } from "react";
import { DeeplinkedCourse } from "./deep-link.types";
import { Button } from "../components";
import { classNames } from "../utils/classnames";
import { useTranslation } from "react-i18next";

export type SelectionListProps = {
  selection: DeeplinkedCourse[];
  deselect: (id: number) => void;
  onSubmit: () => void;
};

export const SelectionPanel: FC<SelectionListProps> = (props) =>
  <section className="
    fixed bottom-4 right-4 max-h-[90vh] max-w-[50vw]
    bg-white shadow-lg p-4 rounded-lg
    border-gray-300 border
    overflow-hidden
  ">
    <SelectionList {...props} />
  </section>;

export const SelectionList: FC<SelectionListProps> = (props) => {
  const { t } = useTranslation(undefined, { keyPrefix: "deepLinking" });
  const { selection, deselect, onSubmit } = props;
  const [isFolded, setIsFolded] = useState(true);
  const foldIcon = `${process.env.PUBLIC_URL}/${isFolded ? "chevron-right" : "chevron-down"}.svg`;

  return (
    <div className="flex flex-col gap-2 overflow-hidden max-h-[calc(90vh-4rem)]">
      <button
        type="button"
        onClick={() => setIsFolded(!isFolded)}
        className={classNames(
          "flex flex-row gap-1 items-center",
          !isFolded && "font-semibold",
        )}
      >
        <img src={foldIcon} alt="" className="scale-90" />
        <span>{t("coursesSelected", { count: selection.length })}{isFolded ? "" : ":"}</span>
      </button>
      {!isFolded &&
        <>
          <hr className="border-gray-900" />
          <div className="flex flex-col gap-4 overflow-y-auto">
            <ul className="flex flex-col text-sm divide-y divide-gray-200">
              {selection
                .sort((a, b) => a.courseName.localeCompare(b.courseName))
                .map((item) => (
                  <li
                    key={item.courseId}
                    className="group flex flex-row gap-2 items-baseline py-2"
                  >
                    <button onClick={() => deselect(item.courseId)} className="flex flex-row gap-2 items-center text-left">
                      <img className="opacity-10 group-hover:opacity-100" src={`${process.env.PUBLIC_URL}/cross.svg`} alt="Close" />
                      <p className="break-words line-clamp-3">
                        {item.courseName}
                      </p>
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </>
      }
      {selection.length > 10 &&
        <div className="-mt-1 -mb-3 text-xs text-red-500 border border-red-500 rounded-md p-[4px] font-bold text-center text-balance tracking-wide">
          <p>
            {t`performanceWarning`}
          </p>
        </div>
      }
      <button
        className="mt-2 w-full bg-gray-900 text-white rounded-lg text-center p-1"
        onClick={onSubmit}
      >
        {t`cta`}
      </button>
    </div>
  );
};

