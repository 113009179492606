import { createBrowserRouter } from "react-router-dom";
import { Courses } from "./courses/courses.component";
import { Errors } from "./errors/errors.component";
import { Logout } from "./Logout";
import { ExamPage } from "./exam/components/exam-page";
import { DeepLink } from "./deep-link/deep-link.component";
import { CertificatesPage } from "./certificate/CertificatesPage";
import { CertificateDetailsPage } from "./certificate/CertificateDetailsPage";
import { CourseDetailsPage } from "./course-details/CourseDetailsPage";

const AppRouter = createBrowserRouter([
  {
    path: "/deeplink/:ltik",
    Component: DeepLink,
  },
  {
    path: "/errors/:errorToken",
    Component: Errors,
  },
  {
    path: "/courses/:ltik",
    Component: Courses,
  },
  {
    path: "/course-details/:id",
    Component: CourseDetailsPage,
  },
  {
    path: "/course-details/:id/:ltik",
    Component: CourseDetailsPage,
  },
  {
    path: "/certificates",
    Component: CertificatesPage,
  },
  {
    path: "/logout",
    Component: Logout,
  },
  {
    path: "/exam/:courseId",
    Component: ExamPage,
  },
  {
    path: "/certificate",
    Component: CertificateDetailsPage
  },
  {
    path: "*",
    Component: Errors,
  },
]);

export { AppRouter };
