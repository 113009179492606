import { zip } from "fflate";
import { useEffect, useState } from "react";
import { Certificate } from "../../@types/certificate.types";
import useCertificatePdf, { createFilename } from "../hooks/useCertificatePdf";

export type Zippable = {
  data: Uint8Array;
  name: string;
};

const getNonCollidingFilename = (name: string, files: Zippable[]) => {
  let collision: Zippable | undefined;
  let candidate = name;
  do {
    collision = files.find((file) => file.name === candidate);
    if (collision) {
      const {base, ext, n} = /^(?<base>.+?)(?:-(?<n>\d+))?\.(?<ext>\w+$)/.exec(candidate)?.groups ?? {};
      candidate = `${base}-${(n ? +n : 0)+1}.${ext}`;
    }
  } while (collision);
  return candidate;
};

const zipAll = async (files: Zippable[]): Promise<Uint8Array> => {
  const contents = Object.fromEntries(files.map((file) => [file.name, file.data]));
  return new Promise<Uint8Array>((resolve, reject) => {
    zip(contents, { level: 0 }, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

export const useBulkDownload = () => {
  const [certificates, setCertificates] = useState<Certificate[]>();
  const [error, setError] = useState<string>();
  const [pdfs, setPdfs] = useState<Zippable[]>();
  const [inProgress, setInProgress] = useState(false);
  const { generate, downloadError: genError } = useCertificatePdf();
  const [zip, setZip] = useState<Uint8Array>();
  const [filename, setFilename] = useState<string>();

  useEffect(() => {
    if (genError && !error) {
      setError(genError);
    }
  }, [genError]);

  useEffect(() => {
    if (inProgress && certificates && pdfs && certificates.length === pdfs.length) {
      (async () => {
        try {
          setZip(await zipAll(pdfs));
          setPdfs(undefined);
        } catch (e) {
          const msg = e instanceof Error ? e.message : JSON.stringify(e);
          setError(msg);
          console.error(msg);
        }
      })();
      setInProgress(false);
    }
  }, [inProgress, certificates, pdfs]);

  useEffect(() => {
    if (error) return;
    if (!inProgress) return;
    if ((certificates?.length ?? 0) <= (pdfs?.length ?? 0)) return;
    (async () => {
      try {
        if (!certificates) throw new Error("Certificates not set, internal error.");
        const cert = certificates[pdfs?.length ?? 0];
        const blob = await generate(cert);
        if (!inProgress) return;
        if (!blob) throw new Error("No PDF generated");
        const data = new Uint8Array(await blob.arrayBuffer());
        const name = getNonCollidingFilename(createFilename(cert), pdfs ?? []);
        setPdfs((pdfs) => ([...pdfs ?? [], { data, name }]));
      } catch (e) {
        const msg = e instanceof Error ? e.message : JSON.stringify(e);
        setError(msg);
        console.error(msg);
      }
    })();
  }, [certificates, error, pdfs, inProgress, generate]);

  const cancel = () => {
    setInProgress(false);
    setPdfs(undefined);
  };

  const initiate = async (certificates: Certificate[], filename: string) => {
    setCertificates(certificates);
    setError(undefined);
    setZip(undefined);
    setPdfs([]);
    setInProgress(true);
    setFilename(filename);
  };

  const downloadZip = () => {
    if (!zip) return;
    const url = window.URL.createObjectURL(new Blob([zip], { type: "application/zip" }));
    const a = document.createElement("a");
    a.href = url;
    a.download = `${filename}.zip`;
    a.click();
  };


  return {
    progress: zip ? certificates?.length ?? 0 : pdfs?.length ?? 0,
    total: certificates?.length ?? 0,
    error,
    initiate,
    downloadZip,
    cancel,
    inProgress,
    zip,
  };
};
