import { FC } from "react";
import { CourseCard } from "../course-card/course-card.component";
import { AssignedCourse } from "./courses.types";

export type CourseGridProps = {
  courses: AssignedCourse[];
};

export const CourseGrid: FC<CourseGridProps> = ({courses}) =>
  <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-6">
    { Array.isArray(courses) && courses.length > 0 && courses.map((course) =>
      <CourseCard key={course.course.id} course={course}></CourseCard>)
    }
  </div>;
