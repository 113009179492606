import { FC } from "react";

export type PageTitleProps = {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
};

export const PageTitle: FC<PageTitleProps> = ({ title, subtitle }) =>
  <div className="flex flex-col gap-2">
    <h1 className="text-xl sm:text-2xl md:text-3xl md:-mt-1">
      {title}
    </h1>
    {subtitle && typeof subtitle === "string"
      ? <p className="text-sm sm:text-base md:text-lg text-gray-700">{subtitle}</p>
      : subtitle ? subtitle : null
    }
  </div>;

