import { FC, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CertificateViewer } from "./certificate-viewer";
import { getCertificateByCourseId, getCertificateById } from "./certificate.service";
import { Loader } from "../loaders/loader.component";
import { useQuery } from "@tanstack/react-query";
import { useErrorRedirect } from "../app/hooks/useErrorRedirect";


type CertificateIdentifier = {
  by: "course" | "certificate"
  id: number
} | { by: "none" };

const getIdentifier = (courseId?: string, certificateId?: string): CertificateIdentifier => {
  const by =
    courseId ? "course"
      : certificateId ? "certificate"
        : "none";

  return by === "none"
    ? { by }
    : {
      by,
      id: courseId ? +courseId : +(certificateId as string)
    };
};


export const CertificateDetailsPage: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const certificateIdent = useMemo(() => {
    const courseId = searchParams.get("course") ?? undefined;
    const certificateId = searchParams.get("certificate") ?? undefined;
    return getIdentifier(courseId, certificateId);
  }, [searchParams]);

  const { data: certificate, isError } = useQuery({
    queryFn: async () => {
      if (certificateIdent.by === "none") return null;
      return (await (certificateIdent.by === "course"
        ? getCertificateByCourseId(certificateIdent.id)
        : getCertificateById(certificateIdent.id)
      )) ?? null;
    },
    queryKey: [
      "certificate",
      certificateIdent.by,
      certificateIdent.by !== "none" ? certificateIdent.id : -1
    ]
  });

  useErrorRedirect(isError || certificateIdent.by === "none");

  const content = certificate
    ? <CertificateViewer goBack={() => navigate(-1)} certificate={certificate} />
    : <Loader />;


  return (
    <main className="h-full">
      {content}
    </main>);
};
