import { classNames } from "../../utils/classnames";

export type RadioButtonProps = {
  label: string
  group?: string
  value: string|number
  selection: string|number
  setSelection: (value: string|number) => void
}

export const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const { label, group, value, selection, setSelection } = props;
  const isChecked = selection === value;
  return (
    <label
      className={classNames(
        "focus-within:outline-dotted focus-within:outline-1 focus-within:outline-blue-700",
        "group flex flex-row gap-2 items-center rounded-full hover:bg-gray-200 p-2",
      )}>
      <input
        className="appearance-none"
        type="radio"
        name={group}
        value={value}
        checked={isChecked}
        onChange={(e) => e.target.checked && setSelection(value)}
      />
      <div className={classNames(
        "min-w-4 min-h-4 rounded-full",
        isChecked
          ? "border-4 border-blue-700"
          : "border border-gray-700 group-hover:border-4 grou-hover:border-gray-500",
      )} />
      <p>{label}</p>
    </label>
  );
};
