import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Course } from "../../courses/courses.types";
import { classNames } from "../../utils/classnames";
import { isExamUnlockedOrCourseCompleted } from "../../utils/course.utils";
import { CourseProgressState } from "../course-progress.enum";

export type ChildCourseItemProps = {
  item: Course | "exam";
  state?: CourseProgressState;
  isLocked?: boolean;
  isSelected: boolean;
  onItemSelect: () => void;
  index: number;
  count: number;
};

const STATUS_COLORS: Record<CourseProgressState, string> = {
  [CourseProgressState.NOTSTARTED]: "bg-amber-400",
  [CourseProgressState.INPROGRESS]: "bg-amber-400",
  [CourseProgressState.COMPLETED]: "bg-emerald-400",
  [CourseProgressState.FINISHEDWATCHING]: "bg-emerald-400",
};

export const ChildCourseItem: FC<ChildCourseItemProps> = (props) => {
  const { item, state, isLocked, isSelected, onItemSelect, index, count } =
    props;
  const { t } = useTranslation();
  const { statusColor, statusIcon } = useMemo(() => {
    const isDone =
      item === "exam"
        ? state === CourseProgressState.COMPLETED
        : isExamUnlockedOrCourseCompleted(state);
    return {
      statusColor: isLocked
        ? "bg-black bg-opacity-10"
        : item === "exam" && !isDone
          ? "bg-amber-400"
          : STATUS_COLORS[state ?? CourseProgressState.NOTSTARTED],
      statusIcon: isLocked
        ? "/locked.svg"
        : isDone
          ? "/check20.svg"
          : "/current.svg",
    };
  }, [state, isLocked, item]);

  return (
    <button
      onClick={!isLocked ? onItemSelect : undefined}
      className={classNames(
        "flex flex-row gap-4 items-stretch md:border-r w-full",
        isLocked && "cursor-not-allowed text-gray-500",
        isSelected &&
          "bg-white text-black font-medium relative md:border-r-white shadow-md",
        isSelected && !index && "md:border-b md:border-b-gray-400",
        isSelected && index === count - 1 && "md:border-t md:border-t-gray-400",
        !isSelected && "md:border-r-gray-400",
        !isSelected && !isLocked && "bg-transparent text-gray-800",
      )}
    >
      <div
        className={classNames(
          "max-w-6 min-w-6 px-1 flex flex-col items-center justify-center",
          statusColor,
          isSelected && "bg-opacity-50",
        )}
      >
        <img
          className={classNames(
            "w-full",
            isLocked ? "opacity-50" : "opacity-80",
          )}
          src={statusIcon}
          alt=""
        />
      </div>
      <p
        className="
        w-full text-left leading-snug py-3 text-balance -mb-[1px] 
        border-b border-b-black border-opacity-10 mr-4 break-words overflow-hidden
        "
      >
        {item !== "exam" ? item.title : t`exam.title`}
      </p>
    </button>
  );
};
