import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NO_ACCESS_TOKEN } from "../@types/errors.types";
import { useErrorRedirect } from "../app/hooks/useErrorRedirect";
import { getCertificateByCourseId } from "../certificate/certificate.service";
import { Button } from "../components";
import { Modal } from "../components/Modal/Modal";
import { PageLayout } from "../components/Pages/PageLayout";
import { getCourseDetails } from "./course-details.service";
import {
  createParentCourseProgress,
  getCourseProgress,
} from "./course-progress.service";
import { CourseDetails } from "./CourseDetails";
import { MultiCourseDetails } from "./multi-course/MultiCourseDetails";

export type CourseDetailsParams = {
  id: string;
  ltik?: string;
};

const lastExamTakenAtLeast24HoursAgo = (dateStr: string) => {
  const date = new Date(dateStr);
  return Date.now() - date.getTime() > 24 * 60 * 60 * 1000;
};

export const CourseDetailsPage: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<CourseDetailsParams>();
  const { ltik } = useParams<CourseDetailsParams>();
  const isDeepLinkSession = !!ltik;
  const ltikInUse = localStorage.getItem("lti_app_token") as string;
  const [showExamUnavailableModal, setShowExamUnavailableModal] =
    useState(false);

  const queryClient = useQueryClient();

  const { data: course, error: courseError } = useQuery({
    queryKey: ["course", id],
    queryFn: () => getCourseDetails(+id!),
    enabled: !!id,
  });

  const { data: certificate } = useQuery({
    queryKey: ["courseCertificate", course?.id],
    queryFn: () => getCertificateByCourseId(course!.id),
    enabled: !!course?.id,
  });

  const {
    data: courseProgress,
    error: progressError,
    isSuccess: isCourseProgressSuccess,
  } = useQuery({
    queryKey: ["courseProgress"],
    queryFn: () => getCourseProgress(course!.id),
    enabled: !!course?.id,
  });

  const {
    mutate: mutateParentCourseProgress,
    isError: isMutationError,
    isSuccess: isMutationSuccess,
  } = useMutation({
    mutationFn: () =>
      course
        ? createParentCourseProgress(course!.id)
        : new Promise(() => undefined),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["courseProgress"] });
      queryClient.invalidateQueries({ queryKey: ["childProgresses"] });
    },
  });

  useEffect(() => {
    if (
      !courseProgress &&
      isCourseProgressSuccess &&
      !isMutationError &&
      !isMutationSuccess
    ) {
      mutateParentCourseProgress();
    }
  }, [
    isCourseProgressSuccess,
    courseProgress,
    isMutationSuccess,
    isMutationError,
    mutateParentCourseProgress,
  ]);

  const canTakeExam =
    !certificate || lastExamTakenAtLeast24HoursAgo(certificate.completedOn);

  const onTakeExam = () => {
    if (canTakeExam && course) {
      navigate(`/exam/${course.id}`);
    } else {
      setShowExamUnavailableModal(true);
    }
  };

  useErrorRedirect(courseError || progressError);
  useErrorRedirect(!ltikInUse && !ltik, NO_ACCESS_TOKEN);
  if (ltik) localStorage.setItem("lti_app_token", ltik);

  const onGoBack = () => (isDeepLinkSession ? window.close() : navigate(-1));

  return (
    <PageLayout>
      <div className="flex flex-col md:flex-row items-stretch gap-2 md:gap-4">
        <Button label={`❮ ${t("common.goBack")}`} action={onGoBack} />
        {!!course && !!certificate && (
          <Button
            className="flex flex-row items-center gap-x-2"
            action={() => navigate(`/certificate?course=${course.id}`)}
            kind="secondary"
          >
            <img src="/certificate.svg" alt="" className="w-4 h-4" />
            {t("certificates.goToCertificate")}
          </Button>
        )}
        <Button
          className="flex flex-row items-center gap-x-2"
          action={() => navigate(`/certificates`)}
          kind="secondary"
        >
          {t("certificates.allCertificates")}
        </Button>
      </div>
      <h1 className="font-bold text-3xl">{course?.title}</h1>
      {course?.children?.length ? (
        <MultiCourseDetails
          course={course}
          onTakeExam={onTakeExam}
          courseProgress={courseProgress ?? undefined}
        />
      ) : course ? (
        <CourseDetails
          course={course}
          onTakeExam={onTakeExam}
          courseProgress={courseProgress ?? undefined}
        />
      ) : null}

      {showExamUnavailableModal && (
        <Modal
          className="w-[min(80vw,480px)]"
          message={t`courseDetail.exam24HourThrottleMsg`}
          buttons={["ok"]}
          onAction={() => setShowExamUnavailableModal(false)}
          hasXCloseButton
        />
      )}
    </PageLayout>
  );
};
