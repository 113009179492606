import { FC } from "react";
import { ExamQuestion } from "../../@types/exam.types";
import { RadioButtonGroup } from "../../components/buttons/RadioButtonGroup";

export type ExamQuestionProps = {
  question: ExamQuestion
  index: number
  id: number
  answerGiven: number
  setAnswerGiven: (questionIndex: number, answerIndex: number) => void
}

export const ExamQuestionItem: FC<ExamQuestionProps> = (props) => {
  const { question, index, id: questionId, answerGiven, setAnswerGiven } = props;
  const radioGroupName = `question-${question.id}`;
  const items = question.examQuestionReponses.map(({ id, response }) => ({ label: response, value: id}));
  const setSelection = (value: string|number) => setAnswerGiven(questionId, Number(value));
  return <section className="flex flex-col gap-2">
    <h2>
      {`${index+1}. ${question.question}`}
    </h2>
    <RadioButtonGroup group={radioGroupName} items={items} selection={answerGiven} setSelection={setSelection} />
  </section>;
};
