import { useEffect, useState } from "react"
import { CustomerDetails } from "./courses.types"
import { getCustomerDetails } from "./courses.service"
const fetchedCustomerDetails = async (setterFn:Function)=>{
    const customerDetails = await getCustomerDetails()
    setterFn(customerDetails);
}
const useCustomerDetails = () => {
    const [customerDetails, setCustomerDetails] = useState<CustomerDetails | null>(null)
    useEffect(() => {
        fetchedCustomerDetails(setCustomerDetails)
    }, [])
    return {customerDetails}
}

export {useCustomerDetails}