import React, { FC } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import { Loader } from "../loaders/loader.component";
import { CourseGrid } from "./course-grid";
import { useCourseFeed } from "./useCourseFeed";
import { useCustomerDetails } from "./useCustomerDetails";
import { CoursesHeader } from "./courses-header";
import { AssignedCourse } from "./courses.types";
import { GENERIC_ERROR_MESSAGE, NO_ACCESS_TOKEN } from "../@types/errors.types";
import { useErrorRedirect } from "../app/hooks/useErrorRedirect";
import { PageLayout } from "../components/Pages/PageLayout";


type Params = {
  ltik: string;
}

type CourseFeedProps = {
  feedTrigger: React.RefObject<HTMLDivElement>;
  courses: AssignedCourse[];
}

const CourseFeed: FC<CourseFeedProps> = (props) => {
  const { courses, feedTrigger } = props;
  return (
    <>
      <CourseGrid courses={courses} />
      <div
        ref={feedTrigger}
        className="mt-12"
      >&nbsp;</div>
    </>
  );
};

export const Courses: React.FC = () => {
  const { t } = useTranslation();
  const { ltik } = useParams<Params>();
  const navigate = useNavigate();
  if (!ltik) navigate(`/errors/${NO_ACCESS_TOKEN}`);
  localStorage.setItem("lti_app_token", ltik as string);

  const {
    courseFeed,
    isLoading,
    setSearchQuery,
    searchQuery,
    totalCourseCount,
    feedTriggerRef,
    hasError
  } = useCourseFeed<HTMLDivElement>();
  const { customerDetails } = useCustomerDetails();

  useErrorRedirect(hasError);

  const title: JSX.Element | string =
    searchQuery && totalCourseCount ? <Trans i18nKey="courses.titleNCoursesFound" values={{ count: totalCourseCount }} />
      : searchQuery ? t`courses.titleNoCoursesFound`
        : t`courses.titleAllCourses`;

  const content =
    customerDetails?.isLtiV3DeeplinkingEnabled ? <p className="text-3xl">{t`courses.bodyDeeplinkEnabled`}</p>
      : isLoading ? <Loader />
        : <>
          <CoursesHeader
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            title={title}
          />
          <CourseFeed feedTrigger={feedTriggerRef} courses={courseFeed} />
        </>


  return <PageLayout>
    {content}
  </PageLayout>;
};

