import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "../../components/buttons/Button";
import { RadialProgressBar } from "../../components/RadialProgressBar";


export type ExamResultProps = {
  totalQuestions: number
  correctAnswers: number
  passed: boolean
  openCertificate: () => void
  handleClose: () => void
}


const ResultLabel: FC<{passed: boolean, percent: number}> = ({percent, passed}) => {
  const { t } = useTranslation();
  return <div className="flex flex-col items-center">
    <p className="text-2xl font-semibold">{percent}%</p>
    <p className="text-sm">{passed ? t`exam.passed` : t`exam.failed`}</p>
    <img className="scale-150 mt-2" src={passed ? "/check.svg" : "/cross.svg"} alt="" />
  </div>;
};

export const ExamResult: FC<ExamResultProps> = (props) => {
  const {totalQuestions, correctAnswers, passed, handleClose, openCertificate} = props;
  const { t } = useTranslation();

  return (
    <main className="flex flex-col gap-16 w-80 max-w-80 text-center">
      <div className="flex flex-col gap-8">
        <h1 className="w-full">{t`exam.yourResult`}</h1>
        <div className="w-40 mx-20">
          <RadialProgressBar
            progress={Math.floor(correctAnswers / totalQuestions * 100)}
            strokeColor={passed ? "green" : "red"}
            strokeWidth={2}
            label={<ResultLabel passed={passed} percent={Math.floor(correctAnswers / totalQuestions * 100)} />}
          />
        </div>
        <p className="text-gray-600 font-medium text-lg">
          <Trans
            i18nKey="exam.correctAnswersOurOfTotal"
            values={{correctAnswers, totalQuestions}}
            components={[<strong key={1}></strong>]} />
        </p>
      </div>
      <div className="flex flex-col gap-2">
        { passed &&
        <Button
          label={t`certificates.goToCertificate`}
          action={() => openCertificate()}
        />}
        <Button
          kind={passed ? "secondary" : "primary"}
          label={t`common.close`}
          action={handleClose} />
      </div>
    </main>
  );
};
