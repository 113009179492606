import React from "react";
import { useParams } from "react-router-dom";
import { ErrorPageParams } from "../@types/errors.types";
import { useErrors } from "./errors.hook";


const Errors:React.FC = () => {
  const { errorToken } = useParams<ErrorPageParams>();
  const { title, description } = useErrors(errorToken!);
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="grid place-items-center">
          <img src={`${process.env.PUBLIC_URL}/error-image.jpeg`} className="object-cover h-48 w-96" alt="Error" />
        </p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{title}</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">{description}</p>
        <div className="mt-10 flex items-center justify-center gap-x-6"></div>
      </div>
    </main>
  );
};

export {Errors};
