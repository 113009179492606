import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { RouterProvider } from "react-router-dom";
import { Errors } from "./errors/errors.component";

import { Footer } from "./Footer";
import { Header } from "./Header";
import "./index.css";
import { AppRouter } from "./app.router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const routesWithoutHeader = ["/deeplink"]

const queryClient = new QueryClient();

// eslint-disable-next-line jest/require-hook
root.render(
  <ErrorBoundary FallbackComponent={Errors}>
    <QueryClientProvider client={queryClient}>
      {!routesWithoutHeader.some((x) => window.location.pathname.startsWith(x)) && <Header />}
      <div className="flex-grow">
        <RouterProvider router={AppRouter} fallbackElement={<Errors />} />
      </div>
      <Footer />
    </QueryClientProvider>
  </ErrorBoundary>
);
