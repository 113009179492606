import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  main: {
    marginHorizontal: 80,
    flexGrow: 1,
    fontSize: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  document: {
    marginTop: 5,
  },
  page: {
    flexDirection: "column",
    marginTop: 20,
    textAlign: "center",
  },
  bgImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  certificateBelongsTo: {
  },
  learner: {
    fontSize: 40,
    marginTop: 20,
    fontWeight: "ultrabold",
  },
  orgName: {
  },
  forCourse: {
    marginVertical: 20,
  },
  courseTitle: {
    fontSize: 28,
  },
  hasSuccessfullyCompleted: {
    marginTop: 25,
  },
  completedOn: {
    marginTop: 90,
  },
  footerText: {
    color: "grey",
  }
});

export default styles;
