import { useNavigate } from "react-router-dom";
import { GENERIC_ERROR_MESSAGE } from "../../@types/errors.types";
import { useEffect } from "react";

/**
 * Redirects to an error page if the condition evaluates to truthy.
 * @param condition The condition itself or a thunk.
 */
export const useErrorRedirect = (
  condition: unknown | (() => unknown),
  errorType?: string,
) => {
  const navigate = useNavigate();
  useEffect(() => {
    const cond = typeof condition === "function" ? condition() : condition;
    if (cond) navigate(`/errors/${errorType ?? GENERIC_ERROR_MESSAGE}`);
  }, [condition, errorType, navigate]);
};
