import { useTranslation } from "react-i18next";
import "./i18n";
import { useEffect } from "react";

const Logout:React.FC = () => {
  const {t} = useTranslation();
  useEffect(() => {
    localStorage.removeItem("lti_app_token");
  }, []);
  return <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div className="text-center">
      <p className="grid place-items-center">
        <img src={`${process.env.PUBLIC_URL}/error-image.jpeg`} className="object-cover h-48 w-96" alt="Error" />
      </p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t("common.logout.title")}</h1>
      <p className="mt-6 text-base leading-7 text-gray-600">{t("common.logout.description")}</p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
      </div>
    </div>
  </main>;
};

export {Logout};
