import { useState } from "react";

import { Certificate } from "../@types/certificate.types";
import { executeRestAPICall } from "../services/generic.service";


const ENDPOINT = `${process.env.REACT_APP_LTI_BACKEND_API}/lti/certificates`;

export const getCertificateByCourseId = async (courseId: number): Promise<Certificate | undefined> => {
  try {
    return await executeRestAPICall({
      method: "GET",
      url: `${ENDPOINT}/course/${courseId}`,
    });
  } catch (error) {
    return undefined;
  }
};

export const getCertificateById = async (certificateId: number): Promise<Certificate | undefined> => {
  try {
    return await executeRestAPICall({
      method: "GET",
      url: `${ENDPOINT}/${certificateId}`,
    });
  } catch (error) {
    return undefined;
  }
};

export const getMyCertificates = async (): Promise<Certificate[]> => {
  try {
    return await executeRestAPICall({
      method: "GET",
      url: `${ENDPOINT}`,
    });
  } catch (error) {
    return [];
  }
};


export const useLazyGetCertificateByCourseId = () => {
  const [certificate, setCertificate] = useState<Certificate>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>();

  const clear = () => {
    setCertificate(undefined);
    setLoading(false);
  };

  const getCertificate = async (id: number) => {
    setLoading(true);
    setError(false);
    try {
      const response = await getCertificateByCourseId(id);
      setCertificate(response);
      setLoading(false);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return { clear, getCertificate, certificate, loading, error };
};


