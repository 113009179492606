import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components";

export type ExamItemProps = {
  onTakeExam: () => void;
  isExamFinished: boolean;
};

export const ExamItem: FC<ExamItemProps> = (props) => {
  const { onTakeExam, isExamFinished } = props;
  const { t } = useTranslation();
  return (
    <div
      className="
      md:container bg-white flex flex-col gap-8 p-8 
      justify-center items-center shadow-sm
      "
    >
      <div className="flex flex-col lg:flex-row gap-8 px-2 md:px-10 justify-center items-center pb-8 border-b border-b-gray-200">
        <img src="/exam-concept.svg" alt="" className="max-h-20 md:max-h-40" />
        <div className="flex flex-col justify-center gap-2 flex-shrink md:max-w-[400px] lg:max-w-[600px]">
          <h2 className="text-center lg:text-left">{t`courseDetail.multiCourseExam.title`}</h2>
          <p className="text-center lg:text-left text-balance leading-relaxed">
            {t(
              isExamFinished
                ? "courseDetail.multiCourseExam.bodyExamFinished"
                : "courseDetail.multiCourseExam.body",
            )}
          </p>
        </div>
      </div>
      <Button
        disabled={isExamFinished}
        kind="primary"
        label={t`courseDetail.startExam`}
        className="px-8"
        action={onTakeExam}
      />
    </div>
  );
};
