import { RadioButton } from "./RadioButton";

export type RadioButtonGroupProps = {
  items: { label: string, value: string | number }[]
  group: string
  selection: string|number
  setSelection: (value: string|number) => void
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = (props) => {
  const { items, group, selection, setSelection } = props;
  return <ul className="flex flex-col">
    {items.map(({label, value}) =>
      <li key={value}>
        <RadioButton
          label={label}
          group={group}
          value={value}
          selection={selection}
          setSelection={setSelection}
        />
      </li>
    )}
  </ul>;
};
