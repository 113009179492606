import { FC } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ExamParams } from "../../@types/exam.types";
import { useExam } from "../hooks/useExam";
import { ExamForm } from "./exam-form";
import { ExamResult } from "./exam-result";
import { GENERIC_ERROR_MESSAGE } from "../../@types/errors.types";
import { Loader } from "../../loaders/loader.component";


export const ExamPage: FC = () => {
  const courseId = useParams<ExamParams>()?.courseId;
  const { exam, questions, result, submitAnswers, loading } = useExam(courseId!);
  const navigate = useNavigate();
  const openCertificate = () => navigate(`/certificate?course=${courseId}`, { replace: true });
  const handleCancel = () => navigate(-1);
  const handleClose = () => navigate(-1);

  let content: JSX.Element;
  if (result) {
    content = <ExamResult
      passed={result.resultPercentage >= 80}
      totalQuestions={result.questionsCount}
      correctAnswers={result.correctAnswers}
      openCertificate={openCertificate}
      handleClose={handleClose}
    />;
  } else if (exam && questions.length) {
    content = <ExamForm
      exam={exam}
      questions={questions}
      handleSubmit={submitAnswers}
      handleCancel={handleCancel}
    />;
  } else if (!loading && !exam) {
    content = <Navigate to={`/errors/${GENERIC_ERROR_MESSAGE}`} />;
  } else {
    content = <Loader />;
  }

  return <div className="mx-4 sm:mx-8 my-12 md:m-20 flex flex-col sm:items-center">
    {content}
  </div>;
};
