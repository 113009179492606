import { Course } from "../courses/courses.types";
import { executeRestAPICall } from "../services/generic.service";

const COURSES_API = `${process.env.REACT_APP_LTI_BACKEND_API}/lti/courses`;

async function getCourseDetails(courseId: number): Promise<Course> {
  const course: Course = await executeRestAPICall({
    method: "GET",
    url: `${COURSES_API}/${courseId}`,
  });
  return course;
}

export { getCourseDetails };
