const ScormCourseContent = (courseURL:string) => {
  return (`<meta name="viewport" content="width=device-width, initial-scale=1">
            <style>
                body {
                    margin: 0;
                }   
                iframe {
                    width: 100vw;
                    height: 100vh;
                    height: var(--app-height);
                    border: 0;
                }
            </style>
            </head>
            <body>
                <div id="thumbnail"></div>
                    <script>
                    const viewportHeight = () => {
                    const doc = document.documentElement;
                        doc.style.setProperty('--app-height', window.innerHeight + "px");
                    }
                    window.addEventListener('resize', viewportHeight)
                    viewportHeight()
                    const iframe = document.createElement("iframe");
                    iframe.src = '${courseURL}';
                    const container = document.getElementById("thumbnail");
                    container?.appendChild(iframe);
                    iframe.onload = () => {
                    const createdIframe = document.querySelector("#thumbnail iframe");
                        if (createdIframe) {
                            // eslint-disable-next-line
                            // @ts-ignore
                            console.log('postMessage', {...localStorage});
                            createdIframe.contentWindow.postMessage({ ...localStorage }, createdIframe.getAttribute("src"));

                            window.addEventListener("message", (event) => {
                                console.log("${window.location.origin}", event.origin, event.data);
                                if (event.origin === "${window.location.origin}") {
                                    createdIframe.contentWindow.postMessage(event.data, createdIframe.getAttribute("src"));
                                } else {
                                    window.opener.postMessage(event.data)
                                }
                            });
                        }
                    };
                    </script>
                </body>`);
};

export {ScormCourseContent};
