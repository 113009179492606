import { ParseKeys } from "i18next";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../components";
import { isExamUnlockedOrCourseCompleted } from "../utils/course.utils";
import { CourseProgressState } from "./course-progress.enum";

const ButtonLabels: Readonly<Record<CourseProgressState, ParseKeys>> = {
  [CourseProgressState.NOTSTARTED]: "courseDetail.start",
  [CourseProgressState.INPROGRESS]: "courseDetail.resume",
  [CourseProgressState.FINISHEDWATCHING]: "courseDetail.startExam",
  [CourseProgressState.COMPLETED]: "courseDetail.repeat",
};

export type LaunchCourseButtonProps = {
  courseProgressState: CourseProgressState;
  onLaunchCourse: () => void;
  onStartExam?: () => void;
  onNextCourse?: () => void;
  nextCourseLabel?: ParseKeys;
};

export const LaunchCourseButton: FC<LaunchCourseButtonProps> = (props) => {
  const { onLaunchCourse, onStartExam, onNextCourse, nextCourseLabel } = props;
  let { courseProgressState } = props;
  if (
    !onStartExam &&
    courseProgressState === CourseProgressState.FINISHEDWATCHING
  ) {
    courseProgressState = CourseProgressState.COMPLETED;
  }
  const action =
    onNextCourse && isExamUnlockedOrCourseCompleted(courseProgressState)
      ? onNextCourse
      : courseProgressState === CourseProgressState.FINISHEDWATCHING
        ? onStartExam
        : onLaunchCourse;
  const { t } = useTranslation();
  return (
    <Button
      type="button"
      className={`rounded-md bg-gray-700 px-8 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-800`}
      action={action}
      kind="primary"
      label={t(
        onNextCourse && isExamUnlockedOrCourseCompleted(courseProgressState)
          ? (nextCourseLabel ?? "courseDetail.nextCourse")
          : ButtonLabels[courseProgressState],
      )}
    />
  );
};
