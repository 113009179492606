import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { SubmitAnswersPayload } from "../../@types/exam.types";
import { useErrorRedirect } from "../../app/hooks/useErrorRedirect";
import { getCourseDetails } from "../../course-details/course-details.service";
import { useLazySubmitAnswers, useLoadExam } from "../exam.service";

const tupleToPayload = ([key, answerId]: [string, number]) => ({
  questionId: Number(key),
  answerId,
});

export const useExam = (courseId: string) => {
  const { exam, loading } = useLoadExam(courseId);
  const { result, lazySubmit } = useLazySubmitAnswers();
  const { data: course, error: courseError } = useQuery({
    queryKey: ["course", +courseId],
    queryFn: () => (courseId ? getCourseDetails(+courseId) : undefined),
  });

  useErrorRedirect(courseError);

  const questionCount = exam
    ? Math.min(exam.totalQuestions, exam.examQuestions?.length)
    : 0;
  const questions = useMemo(() => {
    const result =
      exam && exam.examQuestions
        ? [...exam.examQuestions]
            .sort(() => 0.5 - Math.random())
            .slice(0, questionCount)
        : [];

    result.forEach((q) =>
      q.examQuestionReponses.sort(() => 0.5 - Math.random()),
    );
    return result;
  }, [exam, questionCount]);

  const submitAnswers = (answers: Record<number, number>) => {
    const payload: SubmitAnswersPayload =
      Object.entries(answers).map(tupleToPayload);
    const hasChildren = !!course?.children?.length;
    if (courseId) lazySubmit(Number(courseId), payload, hasChildren);
  };

  return {
    exam,
    questions,
    result,
    submitAnswers,
    loading,
  };
};
