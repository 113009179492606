import { FC } from "react";
import { TabSetProps, TabIdString } from "./TabSet";
import { classNames } from "../../utils/classnames";

export type TabListProps = TabSetProps & {
  activeTab?: TabIdString;
  setActiveTab: (tab: TabIdString) => void;
};

export const TabList: FC<TabListProps> = (props) => {
  const { children, activeTab, setActiveTab } = props;
  return (
    <ul
      role="tablist"
      className="flex flex-row gap-6 border-b border-gray-200 mb-4 flex-wrap"
    >
      {children.map((child) => {
        const isActive = activeTab === child.props.id;
        const { id, label } = child.props;
        return (
          <li key={id}>
            <button
              role="tab"
              disabled={activeTab === id}
              className={classNames(
                "border-b-2 text-lg py-1 -mb-[1px]",
                isActive
                  ? "border-b-gray-700 font-semibold"
                  : "border-transparent text-gray-500 hover:border-b-gray-700 hover:border-opacity-50 duration-200 transition-all",
              )}
              aria-selected={activeTab === id}
              onClick={() => setActiveTab(id)}
            >
              {label}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

