import { useMemo, useState } from "react";
import { DeeplinkedCourse } from "../deep-link.types";

export const useCourseSelection = () => {
  const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set());
  const [courseNames, setCourseNames] = useState<Map<number, string>>(new Map());

  const selection: DeeplinkedCourse[] = useMemo(() => Array
    .from(selectedIds)
    .map((id) => ({
      courseId: id, 
      courseName: courseNames.get(id) ?? "" 
    })), [selectedIds, courseNames]);

  const select = (id: number, name: string) => {
    if (!courseNames.has(id)) {
      setCourseNames((prev) => new Map(prev).set(id, name));
    }
    setSelectedIds((prev) => new Set(prev).add(id));
  };

  const deselect = (id: number) => setSelectedIds((prev) => {
    if (!prev.has(id)) return prev;
    const newSelection = new Set(prev);
    newSelection.delete(id);
    return newSelection;
  });

  const toggleSelection = (id: number, name: string) =>
    selectedIds.has(id) ? deselect(id) : select(id, name);

  return {
    selection,
    selectionSet: selectedIds,
    select,
    deselect,
    toggleSelection,
  };
};

