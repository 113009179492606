import { t } from "i18next";
import { FC, useState } from "react";

import { Exam, ExamQuestion } from "../../@types/exam.types";
import { Button } from "../../components/buttons/Button";
import { ExamQuestionItem } from "./exam-question";


export type ExamFormProps = {
  exam: Exam
  questions: ExamQuestion[]
  handleSubmit: (answers: Record<number, number>) => void
  handleCancel: () => void
}


const UNANSWERED = -1;


export const ExamForm: FC<ExamFormProps> = ({exam, questions, handleSubmit, handleCancel}) => {
  const [answers, setAnswers] = useState<Record<number, number>>({});
  const allAnswered = exam && Object.keys(answers).length === exam.totalQuestions;
  const updateAnswers = (questionId: number, answerId: number) => {
    setAnswers({...answers, [questionId]: answerId});
  };

  return <main className="flex flex-col gap-16">
    <div>
      <h1>{t`exam.title`}</h1>
      <p className="text-gray-600 font-medium text-lg">{exam?.course.title}</p>
    </div>

    <section className="flex flex-col gap-10">
      {questions.map((question, index) =>
        <ExamQuestionItem
          key={question.id}
          question={question}
          index={index}
          id={question.id}
          answerGiven={answers[question.id] ?? UNANSWERED}
          setAnswerGiven={updateAnswers}
        />
      )}
    </section>

    {!allAnswered && <p className="text-red-700">
      {t`exam.pleaseAnswerAllQuestions`}
    </p>}

    <div className="flex flex-row gap-4">
      <Button
        label={t`common.evaluate`}
        action={() => handleSubmit(answers)}
        disabled={!allAnswered}
      />
      <Button
        label={t`common.cancel`}
        action={handleCancel}
        kind="secondary"
      />
    </div>
  </main>;
};
